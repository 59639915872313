@import 'src/styles/mixins';

.root {
  min-width: 275px;
  margin-bottom: 30px;
}
.formControl {
  width: 100%;
}
.bullet {
  display: inline-block;
  margin: 0 2px;
  transform: scale(0.8);
}
.title {
  font-size: 14px;
}
.pos {
  margin-bottom: 12px;
}
.buttonSuccess {
  background-color: green;
  &:hover {
    background-color: rgba(0, 128, 0, 0.58);
  }
}
.buttonClassname {
  position: relative;
}
//createStyles({
//  root: {
//    display: 'flex',
//    alignItems: 'center',
//  },
//wrapper: {
//  margin: theme.spacing(1),
//  position: 'relative',
//},
//buttonSuccess: {
//  backgroundColor: green[500],
//'&:hover': {
//  backgroundColor: green[700],
//},
//},
//fabProgress: {
//  color: green[500],
//  position: 'absolute',
//  top: -6,
//  left: -6,
//  zIndex: 1,
//},
.buttonProgress {
  color: green;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
