.button {
  border-radius: 100% !important;
  padding: 20px 16px !important;
  margin: 16px !important;
}
.wrapper_button {
  display: flex;
  justify-content: flex-end;
}
.flex {
  display: flex;
  align-items: center;
}
.flex_just {
  display: flex;
  justify-content: space-between;
}
.isForm {
  padding: 30px;
}

.appBar {
  position: relative !important;
}
