// Colors
$resolution-blue: #002678;
$tundora: #414141;
$charade: #242732;
$white: #ffffff;
$orange: #fa5c18;
$jumbo: #7c7d84;
$biscay: #18395a;
$ghost: #c6cad0;
$wild-sand: #f4f3f3;
$alto: #dddddd;
$link: #386cdb;
$success: #28a745;
$error: #dc3545;
$primary: #20aeea;

// Breakpoints
$min-mobile: 400px;
$mobile: 768px;
$tablet: 992px;
$desktop: 1200px;
$max-container-width: 1170px;

// Layout
$header-height: 150px;
$mobile-header-height: 50px;
$footer-height: 400px;
$mobile-footer-height: 530px;
