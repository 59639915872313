.navigationActions {
  .action {
    margin-right: 10px;
  }

  .actionRight {
    margin-left: 10px;
    float: right;
  }
}
