@import 'src/styles/mixins';

.root {
  min-width: 275px;
  margin-bottom: 30px;
}
.formControl {
  width: 100%;
}
.bullet {
  display: inline-block;
  margin: 0 2px;
  transform: scale(0.8);
}
.title {
  font-size: 14px;
}
.pos {
  margin-bottom: 12px;
}
.final_test {
  margin-left: 16px;
}
