.title {
  padding-bottom: 20px;
  padding-top: 40px;
}
.buttonFooter {
  margin-top: 30px;
}
.link {
  text-decoration: none;
}
.mr_10 {
  margin-right: 10px;
}
