.wrapper {
}

.form {
}

.actions {
  margin-top: 20px;
}
.formField {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
}
.padLeft {
  padding-left: 3rem;
  width: 100%;
}
.formGroup {
  margin-bottom: 1rem;
}
.title_set {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}
.buttonRemoveItem {
  height: 38px;
  margin-right: 1rem !important;
}
.buttonRemoveList {
  height: 38px;
  margin-left: 1rem !important;
}
.marginTop {
  margin-top: 1rem !important;
}
.list_button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.inline_button {
  margin-top: 2rem;
  //display: inline-block;
  display: flex;
  justify-content: flex-end;
}
