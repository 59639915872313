.buttonFooter {
  margin-top: 30px;
}
.tableBottom {
  margin-bottom: 30px !important;
}
.buttonFooter {
  margin-top: 30px;
}
.link {
  text-decoration: none;
}

.center_row {
  align-items: center;
  justify-content: center;
}
.link {
  text-decoration: none;
}
.mr_10 {
  margin-right: 10px;
}
