.wrapper {
  margin-top: 50px;
}

.form {
  margin-top: 10px;
}

.actions {
  margin-top: 20px;
}
