.wrapper {
  width: 100%;
  height: auto;
  min-height: 735px;
}

.navigationActions {
  .action {
    margin-right: 10px;
  }

  .actionRight {
    margin-left: 10px;
    float: right;
  }
}

.searchBtn {
  width: 100%;
  margin-bottom: 20px !important;
}
