@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.wrapperFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
}
.wrapperTable {
  justify-content: center;
}
.label {
  color: $jumbo;
  margin-bottom: 5px;
  margin-left: 5px;
}

.field {
  display: flex;
  height: 200px;
  width: 100%;

  @include mobileView {
    flex-direction: column;
    height: 400px;
  }
}
.fieldDownload {
  display: flex;
  height: auto;
  width: 100%;
  justify-content: space-between;

  @include mobileView {
    flex-direction: column;
  }
}
.fieldDownloadTable {
  width: 50%;
}
.root {
  width: 40% !important;
  height: 100%;
  max-height: 200px;
  min-height: auto !important;
  outline: none !important;

  @include mobileView {
    width: 100% !important;
    height: 200px;
  }
}

.text {
  font-size: 16px !important;
  color: $jumbo;
}

.textContainer {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preview {
  height: 100%;
  margin-left: 20px;
  width: 60% !important;
  transition: max-width 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobileView {
    margin-left: 0;
    margin-top: 5px;
    width: 100% !important;
    max-height: 200px;
  }
}

.previewHidden {
  min-width: 0;
  max-width: 0;
  width: 0;
}

.previewImg {
  max-width: 100%;
  height: auto;
  max-height: 90%;
}
