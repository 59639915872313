.title {
  padding-bottom: 20px;
  padding-top: 40px;
}
.buttonFooter {
  margin-top: 30px;
}
.link {
  text-decoration: none;
}
.mr_10 {
  margin-right: 10px;
}

.tableHeader {
  padding-top: 20px;
  padding-bottom: 20px;
}

.navigationActions {
  .action {
    margin-right: 10px;
  }

  .actionRight {
    margin-left: 10px;
    float: right;
  }

  .searchField {
  }
}
