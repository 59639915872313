.button {
  border-radius: 100% !important;
  padding: 20px 16px !important;
  margin: 16px !important;
}
.wrapper_button {
  display: flex;
  justify-content: flex-end;
}
.flex {
  display: flex;
  align-items: center;
}
.flex_just {
  display: flex;
  justify-content: space-between;
}

.navigationActions {
  .action {
    margin-right: 10px;
  }

  .actionRight {
    margin-left: 10px;
    float: right;
  }
}

.searchBtn {
  width: 100%;
  height: 3.35rem;
}
